

















































































// Core
import {Component, Mixins, Watch} from 'vue-property-decorator'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Interfaces
import {IWork} from '@store/works/Interface'

// Styles
import './workList.scss'

@Component({
  name: 'WorkList',
  components: {
    'v-form-add-fact': () => import('@/components/forms/FormAddFact.vue'),
    'v-form-formation-id': () => import('@/components/forms/FormFormationId.vue'),
  }
})
export default class WorkList extends Mixins(GetStoreMixin) {

  constructor() {
    super();
    this.mode = this.$route.name as string
  }

  private showDialogFact: boolean = false
  private showDialogFormation: boolean = false
  private dialogFormationTitle: string = ''
  private selectedWork: IWork | null = null
  private dialogFormationId: number | null = null


  private sort = [
    {title: 'Дата по возрастанию', direction: 'asc_date'},
    {title: 'Дата по убыванию', direction: 'desc_date'},
    {title: 'Процент выполнения по возрастанию', direction: 'asc_percent'},
    {title: 'Процент выполнения по убыванию', direction: 'desc_percent'},
  ]

  private sortValue: string | null = null

  private search: string = ''
  private mode: string



  private openFormFormation(work) {
    this.dialogFormationTitle = work.name
    this.showDialogFormation = true
    this.dialogFormationId = work.id
  }

  private addFact(work){
    this.selectedWork = work
    this.showDialogFact = true
  }

  @Watch('mode')
  private changeMode(newVal){
    this.search = ''
    this.sortValue = null

    this.$router.push({name:newVal})
  }
}
